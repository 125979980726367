.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.container {
  max-width: 1280px;
}
button {
  &:focus {
    box-shadow: initial !important;
    outline: none !important;
  }
}
// .swal2-popup {
//   background-color: #e5f5bd !important;
// }
.swal2-title {
  color: #1a3129;
}
.swal2-confirm {
  background-color: #1a3129;
  padding: 0.625em 4.1em;
}
.swal2-warning {
  border-color: #1a3129 !important;
  color: #1a3129 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .br-block {
    display: block;
  }
}
@media (max-width: 575px) {
  p {
    font-size: 18px;
  }
}
.payment-error {
  color: red;
}
.ant-spin-dot-holder {
  .ant-spin-dot-item {
    background: #1a3129;
  }
}
.payment-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
