@import "../sass/variables.scss";
.banner {
  > img {
    width: 100%;
  }
  .banner-cont {
    text-align: center;
    margin-top: 30px;
    h3 {
      color: $green-color;
      font-weight: 700;
      font-size: 27.68px;
      line-height: 41.53px;
    }
    h2 {
      border-bottom: 4.81px solid $light-green;
      display: inline-block;
      font-size: 29px;
      font-weight: 600;
      margin: 30px 0 16px;
    }
    p {
      margin: 26px 0 48px;
    }
    .banner-btn {
      display: flex;
      justify-content: center;
      a {
        @include banner-btn-style;
        &:first-child {
          margin-right: 22px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .banner .banner-cont {
    .banner-btn a {
      font-size: 18px;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20.68px;
    }
    h4 {
      font-size: 20px;
    }
  }
}
