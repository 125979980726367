@import "./variables.scss";

.footer {
  background-color: $primary-color;
  padding: 30px 16px;
  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px;
    li {
      a {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: $white;
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  .footer-box {
    padding: 20px;
    background-color: $sec-color;
    border-radius: 8px;
    border: 1px solid #397460;
    .contact {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1em;
      div {
        border: 1px solid #397460;
        border-radius: 4px;
        padding: 14px 0;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $white;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 4px;
        }
      }
    }
  }
  .copyright-text {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;
    color: $white;
  }
}
@media (max-width: 575px) {
  .footer .footer-box .contact {
    grid-template-columns: repeat(1, 1fr);
  }
}
