$primary-color: #1a3129;
$sec-color: #2c5446;
$green-color: #468671;
$grey-black: #1a3129;
$light-green: #cbea7b;
$light-grey: #e5f5bd;
$box-bg: #f6fbe9;
$green-90: #eef8d3;
$bold: 700;
$white: #ffffff;

@mixin banner-btn-style {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  background-color: $light-grey;
  color: #000;
  transition: background-color 0.3s;
  border-radius: 9.62px;
  height: 78.9px;
  max-width: 378px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;

  &:hover {
    background-color: $light-green;
    text-decoration: none;
  }
}
