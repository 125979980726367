@import "../../assets/sass/variables.scss";
.characteristics {
  text-align: center;
  margin: 80px 0;
  h3 {
    font-size: 60px;
    font-weight: $bold;
    margin: 6px;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 50px;
  }
  .characteristics-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    .characteristics-box {
      text-align: left;
      border-radius: 10px;
      border: 1px solid $light-green;
      padding: 30px 30px;
      background-color: $box-bg;
      .box-header {
        display: flex;
        align-items: center;
        h5 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 27px;
          padding-right: 40px;
        }
        img {
          margin-right: 10px;
        }
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 20px;
        line-height: 21px;
        &.expanded {
          -webkit-line-clamp: unset;
        }
      }
      .read-more-btn {
        background: none;
        border: none;
        color: #1a3129;
        cursor: pointer;
        text-decoration: underline;
        display: inline-block;
        margin-top: 10px;
      }
    }
  }
}
.service {
  padding: 40px 0 0;
  h3 {
    font-size: 60px;
    font-weight: 700;
    margin: 6px;
    margin-bottom: 40px;
    text-align: center;
  }
  .services-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 80px;
    .services-box {
      background-color: #f6fbe9;
      border-radius: 10px;
      border: 1px solid #cbea7b;
      img {
        margin-bottom: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      h5,
      a {
        margin: 0px 20px;
      }
      a {
        padding: 10px 10px;
        cursor: pointer;
        border: none;
        background-color: #e5f5bd;
        color: #000;
        transition: background-color 0.3s;
        border-radius: 9.62px;
        font-size: 16px;
        font-weight: 600;
        max-width: 150px;
        margin-top: 20px;
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }
}

.view-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.viewdetail-btn {
  padding: 10px 10px;
  cursor: pointer;
  border: none;
  background-color: #e5f5bd;
  color: #000;
  transition: background-color 0.3s;
  border-radius: 9.62px;
  font-size: 16px;
  font-weight: 600;
  max-width: 150px;
  margin-top: 20px;
  margin-left: auto;
  display: inline-block;
}
@media (max-width: 575px) {
  .characteristics .characteristics-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .characteristics h3 {
    font-size: 36px;
  }
}
