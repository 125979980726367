@import "./variables.scss";

.gallery {
  > img {
    width: 100%;
    margin: 80px 0;
  }
  .gallery-box {
    h3 {
      font-size: 60px;
      font-weight: $bold;
      color: $grey-black;
    }
    a {
      @include banner-btn-style;
      max-width: 235px;
    }
  }
  .gallery-box {
    p {
      margin-bottom: 0.5em;
    }
  }
}
@media (max-width: 575px) {
  .gallery .gallery-box h3 {
    font-size: 36px;
    margin-top: 20px;
  }
}
