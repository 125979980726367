@import "./variables.scss";
.payment-main {
  padding: 20px 0;
  .payment-method {
    padding: 50px;
  }
  .pay-now {
    padding: 50px;
    background-color: #f6fbe9;
    border-radius: 10px;
    .payment-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .pay-btn {
      background-color: #1a3129;
      color: #fff;
      display: block;
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin: 20px 0;
    }
    .payment-secure {
      color: #707f8f;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 36px;
      img {
        margin-right: 8px;
      }
    }
  }
}
.payment-success-container {
  padding: 100px 0;

  text-align: center;
  h3 {
    font-size: 22px;
    margin: 30px 0 30px;
    color: #1a3129;
    font-weight: 600;
  }
  p {
    font-size: 18px;
  }
  .close-btn {
    background-color: #1a3129;
    &:hover {
      background-color: #1a3129 !important;
    }
  }
}
