@import "./variables.scss";
.header {
  background-color: $primary-color;
  .header-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 76px;
    .ant-select {
      .ant-select-selector {
        background: initial;
        border: none;
        .ant-select-selection-item {
          color: #fff;
        }
      }
      .ant-select-arrow {
        svg {
          fill: #fff !important;
        }
      }
    }
  }
}
.ant-drawer {
  .ant-drawer-header {
    background-color: $primary-color;
    .ant-drawer-title {
      color: $white;
    }
    .ant-drawer-close {
      color: $white;
    }
  }
  .ant-drawer-body {
    background-color: $primary-color;
    a {
      display: block;
      font-size: 18px;
      color: $white;
      padding: 10px 18px;
      border-bottom: 1px solid #5450503b;
      &:hover {
        text-decoration: none;
        background-color: #746d6d3d;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-1pg9a38).ant-drawer
  .ant-drawer-header-title {
  justify-content: flex-end;
}
:where(.css-dev-only-do-not-override-1pg9a38).ant-drawer .ant-drawer-header {
  padding: 16px 10px;
  border-bottom: 1px solid #5450503b;
}
:where(.css-dev-only-do-not-override-1pg9a38).ant-drawer .ant-drawer-body {
  padding: 0px 0px;
}
@media (max-width: 767px) {
  .header .header-nav {
    padding: 0;
  }
}
