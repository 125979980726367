@import "./variables.scss";
.nutricheck {
  background-image: url(../images/nutri-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  .nutri {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .nutri-box {
      max-width: 716px;
      margin: 150px auto;
      h3 {
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
      }
      p,
      ol {
        font-size: 20px;
        line-height: 35px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
  .slide-container {
    max-width: 750px;
    min-width: 595px;
    min-height: 600px;
    margin: 50px auto;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 100%;
    }
  }
}
.weight-selection h3,
.multiple-issues-selection h3,
.fitness-level-selection h3,
.body-fat-level-selection h3,
.water-intake-selection h3,
.sugary-foods-selection h3,
.meal-plan-preferences h3,
.dynamic-questions h3 {
  font-size: 36px;
  font-weight: 600;
  color: #1e1d1c;
  margin-bottom: 50px;
}
.age-selection {
  margin: 120px 0;
  h3 {
    font-size: 36px;
    font-weight: 600;
    color: #1e1d1c;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: $primary-color;
    margin-bottom: 75px;
  }
  .ant-slider {
    .ant-slider-rail {
      background-color: #cccecf !important;
      height: 14px;
      border-radius: 20px;
    }
    .ant-slider-track {
      height: 14px;
      background-color: #bbf246;
      border-radius: 20px;
    }

    .ant-slider-handle:after {
      content: url(../images/pause.svg);
      line-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -4px;
      width: 30px;
      height: 30px;
      box-shadow: none;
    }
  }
}
.goal-selection,
.goal-options,
.additional-goal-options {
  margin: 120px 0;
  .goal-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  h3 {
    font-size: 40px;
    font-weight: 600;
    color: #1e1d1c;
    margin-bottom: 40px;
  }
  img {
    margin-top: -15px;
    height: 161px;
    weight: 128px;
  }
  > button {
    background-color: #fcfcfc;
    padding: 0;
    width: 210px;
    height: 223px;
    margin-top: 30px;
    &:not(:last-child) {
      margin-right: 30px;
    }
    &:hover {
      background-color: $light-green;
      div {
        background-color: #e5f5bd;
      }
    }
    div {
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      padding: 32px 0;
      text-align: center;
      background-color: #fafdf2;
    }
  }
  .selected {
    background-color: $light-green;
    div {
      background-color: #e5f5bd;
    }
  }
  .start-button {
    background-color: $light-green;
    border-radius: 999px;
    height: 56px;
    padding: 10px 32px 10px 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $primary-color;
    margin-top: 50px;
    img {
      margin-left: 4px;
      margin-top: 0px;
      height: auto;
    }
  }
}
.meal-plan-preferences {
  h4 {
    margin: 20px 0;
    font-weight: 600;
  }
  .preference-options {
    button {
      border: 2px solid transparent;
      margin-bottom: 20px;
      background-color: #e5f5bd;
      font-size: 18px;
      font-weight: 500;
      color: $primary-color;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
    button.selected {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
}
.start-button {
  background-color: $light-green;
  border-radius: 999px;
  height: 56px;
  padding: 10px 32px 10px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $primary-color;
  margin-top: 50px;
  img {
    margin-left: 4px;
  }
}
.navigation-buttons {
  margin: 0 60px;
  button {
    border: 1px solid #234338;
    border-radius: 999px;
    width: 132px;
    height: 40px;
    img {
      margin-right: 5px;
    }
  }
}
.diet-options {
  button {
    display: block;
    width: 100%;
    // max-width: 594px;
    height: 79px;
    background-color: #e5f5bd;
    text-align: left;
    font-size: 21px;
    font-weight: 600;
    color: $primary-color;
    border-radius: 10px;
    padding-left: 44px;
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
    img {
      margin-right: 10px;
    }
    &:first-child {
      margin-top: 40px;
    }
    &:not(:last-child) {
      margin-bottom: 25px;
    }
    .subtext {
      font-size: 14px;
    }
  }
  .selected {
    background-color: $primary-color;
    color: $white;
  }
}
.weight-selection {
  .weight-inputs {
    .input-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $sec-color;
      }
      div {
        button {
          background-color: $light-green;
          font-size: 16px;
          line-height: 24px;
          padding: 4px 10px;
          border-radius: 999px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
    .input-group {
      display: initial;
      max-width: 594px;
      margin-top: 20px;
      label {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $sec-color;
        margin-top: 20px;
      }
      input {
        width: 100%;
        height: 60px;
        border: 1px solid #468671;
        border-radius: 6px;
      }
    }
  }
}
.weight-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  .increment-decrement-button {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    padding: 0;
    margin: 0 5px;
  }
  div {
    position: absolute;
    right: 20px;
  }
}

.fitness-level-selection {
  padding: 0 30px;
  h3 {
  }
  p {
    margin-bottom: 40px;
  }
  .slider-container {
    .ant-slider {
      .ant-slider-rail {
        background-color: #d9d9d9;
        height: 15px;
        border-radius: 999px;
      }
      .ant-slider-track {
        background-color: $primary-color;
        height: 15px;
        border-radius: 999px;
      }
      .ant-slider-step {
        top: 10px;
      }
      .ant-slider-mark {
        .ant-slider-mark-text {
          // &:last-child {
          // transform: translateX(-72%) !important;
          // }
          top: 15px;
        }
      }
    }
    // :where(.css-dev-only-do-not-override-1pg9a38).ant-slider-horizontal
    //   .ant-slider-mark {
    //   top: 25px;
    // }
    :where(.css-dev-only-do-not-override-1pg9a38).ant-slider
      .ant-slider-mark-text {
      width: 200px;
    }
    .ant-slider-handle {
      &:before,
      &:after {
        top: 5px;
      }
    }
  }
}
.multiple-issues-selection {
  .form-control {
    font-family: system-ui, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.1;
    gap: 0.5em;
    background-color: initial;
    border: initial;
    color: #234338;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    input {
      margin-right: 0;
    }
    input[type="checkbox"]:checked {
      transform: scale(1);
      background-color: $light-green;
      border: none;
    }
    input[type="checkbox"] {
      appearance: none;
      background-color: $white;
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      border: 1px solid #468671;
    }
    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: $white;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    input[type="checkbox"]:disabled {
      --form-control-color: var(--form-control-disabled);

      color: var(--form-control-disabled);
      cursor: not-allowed;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    .form-control + .form-control {
      margin-top: 1em;
    }
  }
}
.issue-option {
  &:hover {
    background-color: $primary-color !important;
    .form-control {
      color: $white;
    }
  }
}
.multiple-issues-selection {
  h3 {
    text-align: left;
  }
  .issues-options {
    .issue-option {
      display: block;
      width: 100%;
      // max-width: 594px;
      height: 79px;
      background-color: #e5f5bd;
      text-align: left;
      font-size: 21px;
      font-weight: 600;
      color: $primary-color;
      border-radius: 10px;
      padding-left: 15px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-top: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}
.multiple-issues-selection {
  text-align: center;
}

.issue-option input {
  margin-right: 10px;
}

.sugary-foods-buttons button {
  display: flex;
  align-items: center;
}

.sugary-foods-buttons button .icon {
  margin-left: 8px;
}

.body-fat-level-selection {
  text-align: center;
  .ant-slider-mark-text {
    display: none;
  }
}

.fat-level-slider {
  width: 80%;
  margin: 20px 0;
}

.fat-level-image {
  width: 100%;
  max-width: 400px;
  height: 500px;
  object-fit: cover;
  margin: 20px 0;
}

.btn-default.start-button {
  margin-top: 60px;
}
.large-options-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  .start-button {
    margin-top: 40px !important;
  }
  .large-option {
    background-color: #e5f5bd;
    border-radius: 10px;
    padding: 10px 20px;
    &:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.large-option.selected {
  background-color: #1a3129;
  color: white;
}
// @media (min-width: 768px) {
//   .fat-level-image {
//     max-width: 190px;
//   }
// }
.body-fat-level-selection {
  .fat-box {
    width: 100%;
    > div {
      &:first-child {
        // width: 30%;
        margin: 0px auto;
      }
    }
  }
  .ant-slider {
    margin: 40px;
    .ant-slider-rail {
      background-color: #d9d9d9;
      height: 15px;
      border-radius: 999px;
    }
    .ant-slider-track {
      background-color: $primary-color;
      height: 15px;
      border-radius: 999px;
    }
    .ant-slider-step {
      top: 10px;
    }
    .ant-slider-handle {
      &:before,
      &:after {
        top: 5px;
      }
    }
  }
}

/* slideTransitions.css */
/* slideTransitions.css */
/* slideTransitions.css */
.slide-enter {
  opacity: 0;
  transform: translateX(100%);
  position: absolute;
  width: 100%;
  top: 0; /* Ensure it aligns with the top */
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease, transform 300ms ease;
  position: relative;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
  position: relative;
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease, transform 300ms ease;
  position: absolute;
  width: 100%;
  top: 0; /* Ensure it aligns with the top */
}

/* Progress Bar */
.progress-bar {
  width: 100%;
  max-width: 1320px;
  margin: 0px auto;
  background-color: #e6e6e6;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-inner {
  height: 8px;
  background-color: $primary-color;
  width: 0;
  transition: width 0.3s;
}
.nutri-progress {
  position: relative;
  max-width: 1320px;
  margin: 0px auto;
  .progress-bar-text {
    position: absolute;
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
    right: 0;
    top: 12px;
  }
}
.gender-selection {
  margin: 120px 0;
  h3 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  button {
    height: 280px;
    width: 280px;
    background-color: #fafdf2;
    border-radius: 10px;
    border: none;
    &:hover {
      background-color: $light-green;
      svg {
        path {
          fill: $white;
        }
      }
    }
    &:not(:last-child) {
      margin-right: 37px;
    }
    svg {
      path {
        fill: $light-green;
      }
    }
  }
  .selected {
    background-color: $light-green;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
@media (max-width: 575px) {
  .nutricheck {
    padding: 50px 15px 0 15px;
    .slide-container {
      min-width: initial;
    }
  }
  .gender-selection {
    text-align: center;
    h3 {
      font-size: 28px;
    }
    button {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
  .navigation-buttons {
    margin: 0 0 20px;
  }
  .age-selection {
    h3 {
      font-size: 28px;
    }
  }
  .goal-selection h3,
  .goal-options h3,
  .additional-goal-options h3 {
    font-size: 28px;
  }
  .goal-selection {
    text-align: center;
  }
  .goal-selection button:not(:last-child),
  .goal-options button:not(:last-child),
  .additional-goal-options button:not(:last-child) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .goal-selection button,
  .goal-options button,
  .additional-goal-options button {
    display: block;
    width: 250px;
  }
  .goal-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .goal-selection,
  .goal-options,
  .additional-goal-options {
    margin: 50px 0;
  }
  .meal-plan-preferences {
    h3 {
      font-size: 28px;
    }
  }
  .fitness-level-selection h3 {
    font-size: 28px;
  }
  .weight-selection h3,
  .multiple-issues-selection h3,
  .fitness-level-selection h3,
  .body-fat-level-selection h3,
  .water-intake-selection h3,
  .sugary-foods-selection h3,
  .meal-plan-preferences h3,
  .dynamic-questions h3 {
    font-size: 24px;
    font-weight: 600;
    color: #1e1d1c;
    margin-bottom: 50px;
  }
  .multiple-issues-selection .issues-options .issue-option {
    padding-left: 10px;
  }
  .multiple-issues-selection .form-control {
    font-size: 16px;
  }
  .multiple-issues-selection .form-control input[type="checkbox"] {
    width: 10%;
  }
  .multiple-issues-selection .form-control span {
    width: 90%;
  }
}
.custom-card {
  border: none;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .custom-card-header {
    background-color: #e5f5bd;
    border-bottom: none;
    padding: 0;
    h5 {
      button {
        color: #234338;
        font-size: 22px;
        font-weight: 600;
        padding: 0.75rem 1.25rem;
        width: 100%;
        text-align: left;
        &:focus,
        &:hover {
          text-decoration: none;
        }
        position: relative;
        .chevron-down {
          position: absolute;
          right: 1.25rem;
        }
      }
      .btn.btn-link.collapsed {
        .chevron-down {
          transform: rotate(0);
        }
      }
      .btn.btn-link {
        .chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .card-body-custom {
    button {
      &:not(:last-child) {
        margin-right: 12px;
      }
      margin-bottom: 12px;
      background-color: #e5f5bd;
      color: #234338;
      border: none;
      &:hover {
        background-color: #234338;
        color: #fff;
      }
    }
    .selected {
      background-color: #234338;
      color: #fff;
    }
  }
}
.card-body-custom .meal-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
